<template>
  <div>
    <gSwiperCommon :bannerType="'show.page.home.pc'"></gSwiperCommon>
    <div class="news">
      <div class="g-layout">
        <div class="g-layout-r" style="margin-left: 0">
          <div class="m-card">
            <div class="home-l">
              <div v-if="hotNewList.length">
                <div :key="index" @click="goToDetails(item)" class="new-list" v-for="(item, index) in hotNewList">
                  <div class="new-list-l">
                    <img :src="item.covers[0]" v-if="item.covers[0]" v-load="'oNews'" />
                    <img src="@/assets/img/empty.png" v-else />
                  </div>
                  <div class="new-list-r">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.content }}</p>
                    <span>
                      {{ item.publishAt | moment("YYYY/MM/DD") }}
                      <!-- {{ $service.SmarDate(new Date(item.publishAt)) }} -->
                    </span>
                  </div>
                </div>
                <div class="load-more" v-if="hotNewList.length >= ToTal">没有更多了</div>
                <!-- <div @click="loadMore" class="load-more" v-else>加载更多</div> -->
                <div @click="loadMore" class="load-more" v-if="!(hotNewList.length >= ToTal) && !clickMore">加载更多</div>
                <div class="no-msg" v-if="clickMore">数据加载中...</div>
              </div>
              <!-- <div class="no-msg" v-else>暂无数据！</div> -->
              <div class="no-msg" v-if="loading && !hotNewList.length">数据加载中...</div>
              <div class="no-msg" v-else-if="!loading && !hotNewList.length">暂无数据</div>
            </div>
          </div>
        </div>
        <div class="g-layout-l" style="margin-left: 1.66667%">
          <newsHot></newsHot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gSwiper from "@components/gSwiper/gSwiper.vue";
import newsHot from "./news-hot";
import gSwiperCommon from "@components/gSwiperCommon/gSwiperCommon.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "home",
  components: {
    gSwiper,
    newsHot,
    gSwiperCommon,
  },
  data () {
    return {
      list: ["https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2634306427,1511025022&fm=26&gp=0.jpg"],
      hotNewList: [],
      ToTal: 0,
      page: 1,
      size: 20,
      loading: true,
      clickMore: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
    }),

    newsOption () {
      let queryStr = `#graphql
      query ($query:_Any!) {
          articleQuery {
            page(query:$query) {
              hasNextPage
              pageIndex
              totalCount
              items {
                articleType
                author
                authorId
                commentCount
                content
                covers
                createAt
                externalUrl
                favoriteCount
                id
                introduction
                itemId
                keywords
                likeCount
                metadata
                toppingIndex
                toppingStartAt
                toppingEndAt
                publishAt
                shareCount
                status
                subtitle
                title
                updateAt
                userCount
                viewCount
              }
            }
          }
        } 
      `;
      let where = {
        equal_itemId: {
          n: "itemId",
          v: this.exhibitionId,
        },
        or: {
          equal_authorType: { n: "authorType", v: "exhibition" },
          equal_authorType2: { n: "authorType", v: "exhibitor" },
          greaterThan_topic: { n: "topic", v: 0 },
        },
      };
      let order = [
        { N: "topic", v: 0 },
        { N: "toppingIndex2", v: 0 },
        { N: "publishAt", v: 0 },
      ];
      let opt = {
        query: queryStr,
        variables: {
          query: {
            page: this.page,
            size: this.size,
            where: where,
            order: order,
          },
        },
      };
      return opt;
    },
  },
  created () {
    this.init();
    document.title = this.exhibitionInfo ? `资讯列表-${this.exhibitionInfo.name}` : "展商秀";
  },
  methods: {
    ...mapActions({
      getNewsList: "news/getNewsList",
      linkToNewsDetails: "linkTo/linkToNewsDetails",
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
    }),
    async setShare () {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: "share.exhibition.config" });
      let share = {
        url: location.href,
        title: "资讯列表-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    init () {
      this.setShare();
      this.getNews();
    },
    async getNews () {
      this.loading = true;
      let data = await this.graphqlPost(this.newsOption);
      if (data.data.articleQuery.page) {
        let res = JSON.parse(JSON.stringify(data.data.articleQuery.page));
        res.items.map((c) => {
          if (c.covers.search(/^\[/) !== -1) {
            c.covers = JSON.parse(c.covers);
          } else {
            c.covers = [c.covers];
          }
        });
        if (this.hotNewList.length) {
          this.hotNewList = [...this.hotNewList, ...res.items];
        } else {
          this.hotNewList = res.items;
        }
        this.hotNewList.map((c) => {
          // if (c.introduction) {
          //   c.introduction = JSON.parse(c.introduction);
          // }
          if (c.text) {
            c = c.text.content;
          }
        });
        this.ToTal = res.totalCount;
      }
      this.loading = false;
      this.clickMore = false;
    },
    goToDetails (item) {
      if (item.externalUrl) {
        window.open(item.externalUrl);
      } else {
        this.linkToNewsDetails(item.id);
      }
    },
    loadMore () {
      this.clickMore = true;
      this.page += 1;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.news {
  width: 1200px;
  margin: 0 auto;
}
.home-l {
  margin-right: 20px;
  width: 880px;
  background: #fff;
  padding: 0 20px;
  .new-list {
    padding: 25px 0;
    border-bottom: 1px solid #f2eded;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-l {
      width: 185px;
      height: 120px;
      background-color: #dcdcdc;
      border-radius: 5px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
    &-r {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
      width: 630px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        color: #333;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #333;
        position: relative;
        top: -6px;
      }
      h2,
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        font-size: 12px;
        line-height: 24px;
        color: #666;
      }
    }
  }
  .new-list:last-child {
    border-bottom: none;
  }
  .new-list:hover {
    h2 {
      @include font_color(#0190fe);
    }
  }
}
.list-box {
  background: #fff;
  padding: 0 20px 20px 20px;
  width: 100%;
  .list-one {
    margin-top: 20px;
    img {
      width: 100%;
      height: 180px;
      display: block;
    }
    p {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      margin-top: 10px;
    }
    span {
      font-size: 12px;
      color: #666;
    }
  }
  .list-one:last-child {
    border: none;
  }
  .list-one:hover {
    p {
      @include font_color(#0190fe);
    }
  }
}
.no-msg {
  font-size: 14px;
  color: #666;
  text-align: center;
  padding: 50px 0;
}
.load-more {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 20px 0;
  cursor: pointer;
}
</style>
